import React, { useEffect, useState, useRef } from "react";
import { useStore } from "../../store";
import HyperCam from "../HyperCam";
import config from "../../config.json";
import Downloader from "../Downloader";
import { useTranslation } from 'react-i18next';

import "./styles.scss";

const Vermodern = () => {
  const seedAmount = useStore((state) => state.seedCount);
  const setScrollLocked = useStore((state) => state.setScrollLocked);
  const { t } = useTranslation();
  return (
    <section
      className="section section--vermodern"
      id="vermodern"
      data-0="display:none;pointer-events:none;position:fixed;top:0;left:0"
      data-236000="display:none;pointer-events:none;position:fixed;top:0;left:0"
      data-236001="display:block;pointer-events:all;position:fixed;top:0;left:0"
    >
      <div
        className="vermodern__title"
        data-end="238000"
        data-236000="bottom:0vh;opacity:0"
        data-236100="bottom:0vh;opacity:1"
        data-238000="bottom:40vh;opacity:1"
        data-238200="bottom:40vh;opacity:0"
      >
              {t('vermodern.title')}
      </div>

      <div
        className="vermodern__text"
        data-238000="display:none"
        data-238200="display:flex"
        data-240000="background-color:rgba(0,0,0,0)"
        data-240200="background-color:rgba(0,0,0,1)"
        data-241000="background-color:rgba(0,0,0,0)"
      >
        <p
          data-238200="opacity:0"
          data-238600="opacity:1"
          data-239000="opacity:0"
        >
           {t('vermodern.blume')}
        </p>
        <p
          data-238600="opacity:0"
          data-239000="opacity:1"
          data-239400="opacity:0"
        >
         {t('vermodern.blatt')}
        </p>
        <p
          data-239000="opacity:0"
          data-239400="opacity:1"
          data-239800="opacity:0"
        >
         {t('vermodern.materie')}
        </p>
        <p
          data-239400="opacity:0"
          data-239800="opacity:1"
          data-240200="opacity:0"
        >
         {t('vermodern.ecosystem')}
        </p>
      </div>
      <div
        className="vermordern__hypercam"
        data-240000="opacity:0"
        data-240100="opacity:1"
        data-255000="opacity:1"
        data-255001="opacity:0"
      >
        <HyperCam url={config.hyperCamUrl}></HyperCam>
      </div>

      <div
        className="vermoden__hcLeave"
        data-0="display:none;"
        data-241000="display:block;"
        data-255000="display:block;"
        data-255001="display:none;"
        onClick={() => {
          // document.querySelector("html").style.overflow = "scroll";
          setScrollLocked(false);
          window.scrollTo(0, 255010);
        }}
      >
        <button>{t('vermodern.continue')}</button>
      </div>
      <div
        className="vermodern__end"
        data-255000="display:none"
        data-255001="display:flex"
      >
         <span>{t('vermodern.credits.a')}<br/>{t('vermodern.credits.b')}</span>
         <div className="credits">
        {t('vermodern.credits.c')}: doublelucky productions
        <br/>{t('vermodern.credits.d')}: Christiane Kühl
        <br/>{t('vermodern.credits.e')}: Chris Kondek
        <br/>{t('vermodern.credits.f')}: Hannes Strobl
        <br/>{t('vermodern.credits.g')}: Laura Piccolo
        <br/>{t('vermodern.credits.h')}: Peter Ehses
        <br/>{t('vermodern.credits.i')}: Thomas Geissl
        <br/>{t('vermodern.credits.j')}: Kim Albrecht, metaLAB (at) Harvard
        <br/>{t('vermodern.credits.k')}: Sage Jenson, Davide Prati 
        <br/><br/>{t('vermodern.credits.l')}
        <br/>{t('vermodern.credits.m')}: doublelucky productions & HAU Hebbel am Ufer (2021).
        </div>

        <p>{t('vermodern.credits.n')}</p>
        {config.downloaderEnabled && <Downloader></Downloader>}
      </div>
    </section>
  );
};

// == Export
export default Vermodern;
