import React, { useEffect, useState } from "react";
import { useStore } from "../../store";
import QRCode from "react-qr-code";
import Restart from "../Restart";
import { growthStages } from "../../constants";
import Seed from "../Seed";
import Image from "../Utils/image";
import { useTranslation } from 'react-i18next';
import config from "../../config.json";

// == Import
import "./styles.scss";

// == Composant
const Saen = ({ setFlowerClicked }) => {
  const { t } = useTranslation();
  const userId = useStore((state) => state.userId);
  const currentStep = useStore((state) => state.currentStep);
  const seed = useStore((state) => state.seed);
  const saen = useStore((state) => state.saen);
  const seedAmount = useStore((state) => state.seedCount);

  const flowers = [
    "e9ce7bbc-926e-48f1-9346-b534d65070a0",
    "b1f81b69-205d-4d2b-aadf-116c0a585992",
    "19792fc1-69b1-4f83-a04c-b7a7a4b1bdee",
    "67b12d9e-ea08-45ad-8675-aae7d487553f",
    "8b4c3797-94a5-44c8-9c64-76fd5d8c15d6",
    "40dc7e45-b3a0-4c6c-9356-c4f740419268",
  ];

  const playFlower = (index) => {
    if(!document.querySelector(`.floweraudio--${index}`)) {
      if (!document.querySelector(`.floweraudio--${index}`).paused)
        document.querySelector(`.floweraudio--${index}`).pause();
      else document.querySelector(`.floweraudio--${index}`).play();
    }
  };

  return (
    <section
      className="section section--saen"
      id="saen"
      data-0="display:none;pointer-events:none;position:fixed;top:0;left:0;pointer-events:none"
      data-4000="display:none;pointer-events:none;position:fixed;top:0;left:0;pointer-events:all"
      data-4001="display:flex;pointer-events:all;position:fixed;top:0;left:0;pointer-events:all"
      data-22200="display:flex;pointer-events:all;position:fixed;top:0;left:0;pointer-events:all"
      data-22201="display:flex;pointer-events:none;position:fixed;top:0;left:0;pointer-events:none"
    >
      <div
        className="saen__text"
        data-4000="right:-600px;top: 130px;"
        data-5000="right:100px;top: 130px;"
        data-6000="right:100px;top: 130px;"
        data-7050="right:200px;top:-1300px"
      >
        {/* {currentStep === 1 && ( */}
        <div className="saen__text__1">
          {/* <br />
          <br /> */}
          {!seed && (
            <>
              <div
                className="saen__qr"
                // data-0="right:-250px"
                // data-5000="right:-250px"
                // data-5200="right:30px"
                // data-6500="opacity:1"
                // data-6600="opacity:0"
              >
                <QRCode value={`${config.uploadUrl}/${userId}`} />
              </div>
              {t("saen.text1.a")}
              <br/>
              <br />
              <br />{t("saen.text1.b")}
              <br />
              <br />{t("saen.text1.bb")}
              <br/>{t("saen.text1.c")}
              <br/>
              <br/>{t("saen.text1.d")}<br />
              <span className="underline">{`${config.uploadUrl}/${userId}`}</span>
              <br />
              <br />
              {t("saen.text1.f")}
              <br />
            </>
          )}
          
          {!seed && <span style={{ color: "orangered" }}><br/> {t("saen.text1.e")}</span>}
          {seed && !saen && (
            <span style={{ color: "orangered" }}>
              <br />{t("saen.text1.i")}
              <br />{t("saen.text1.j")}
            </span>
          )}
          <br />
          {seed && saen && (
            <span style={{ color: "orangered" }}>
              {t("saen.text1.g")}
              <br/> {t("saen.text1.gg")}<br />
              <a href={config.url} target="_blank" style={{ color: "orangered" }}>
              {t("saen.text1.h")}
              </a>
            </span>
          )}
        </div>
        {/* )} */}
      </div>

      <div
        className="saen__flowers"
        data-7000="height:0px;opacity:1"
        data-7500="height:150px;opacity:1"
        data-22200="height:150px;opacity:1"
        data-22400="height:150px;opacity:0"
        data-22410="height:0px;opacity:0"
        data-29000="height:0px;opacity:1"
        data-29500="height:150px;opacity:1"
        data-33300="height:150px;opacity:1"
        data-33500="height:150px;opacity:0"
        data-33510="height:0px;opacity:0"
        data-59000="height:0px;opacity:1"
        data-59500="height:150px;opacity:1"
        data-68500="height:150px;opacity:1"
        data-68700="height:150px;opacity:0"
        data-68710="height:0px;opacity:0"
        data-109000="height:0px;opacity:1"
        data-109500="height:150px;opacity:1"
        data-117000="height:150px;opacity:1"
        data-117200="height:150px;opacity:0"
        data-117210="height:0px;opacity:0"
        data-141000="height:0px;opacity:1"
        data-141500="height:150px;opacity:1"
        data-150000="height:150px;opacity:1"
        data-150200="height:150px;opacity:0"
        data-150210="height:0px;opacity:0"
      >
        {flowers.map((flower, index) => (
          <div
            className={`flower flower${index + 1}`}
            onClick={() => playFlower(index + 1)}
            onMouseOver={() => {
              if (index === 0) {
                if(document.querySelector(".flower1")) document.querySelector(".flower1").style.animation =
                  "wave2 infinite 11s ease-in-out";
                // TODO: i am confused here, what should it do? lock or unlock?
                // document.querySelector("html").style.overflow = "scroll";
                setFlowerClicked(true);
              }
            }}
            style={{
              pointerEvents: index + 1 === currentStep ? "all" : "none",
            }}
            key={index}
          >
            <Image id={flower} />
          </div>
        ))}
      </div>

      <h2
        className="saen__title"
        data-0="opacity:0"
        data-7800="opacity:0"
        data-8100="opacity:1"
        data-9100="opacity:0"
      >
         {t("saen.title")}
      </h2>

      <div
        className="userphoto userphoto--1"
        data-0="opacity:0"
        data-8000="opacity:0;filter: blur(100px);"
        data-9000="opacity:1;filter: blur(100px);"
        data-10000="opacity:1;filter: blur(0px);"
        data-13500="opacity:1"
        data-14000="opacity:0"
      >
        {/* PUT USER IMAGE 1 HERE AND REMOVE THE <img /> */}
        <div
          className="userphoto__info userphoto__info--1"
          // data-10000="display:none;position:absolute"
          // data-10500="display:block;position:absolute"
          // data-14000="display:none;position:absolute"
        >
          {/* Seed {seedAmount}
        <br/>Generation 1
        <br/>{new Date().toLocaleString()} */}
        </div>
        <Seed growthStage={growthStages.saen}></Seed>
      </div>

      <div
        className="saen__floater saen__floater--1"
        data-0="left:80vw;top:100vh"
        data-7000="left:80vw;top:100vh"
        data-9000="left:-30vw;top:50vh"
      >
        <Image id="8cadb367-4685-452f-b047-97ac467fdc2e" />
      </div>
      <div
        className="saen__floater saen__floater--2"
        data-0="display:none"
        data-16000="display:block"
        data-16200="display:none"
        data-16400="display:block"
        data-16500="display:none"
        data-16550="display:block"
        data-16800="display:none"
      >
        <Image id="d8a26ebc-3cb4-42c6-aaaa-a1bbbc3fdc5c" />
      </div>
      <div
        className="saen__floater saen__floater--3"
        data-0="left:100vw;top:40vh"
        data-17000="left:100vw;top:40vh"
        data-20000="left:-60vw;top:80vh"
      >
        <Image id="83a1962b-0087-4474-9e60-1f7fa438ff7b" />
      </div>

      <div
        className="saen__ty"
        data-0="opacity:0"
        data-6050="opacity:0"
        data-6700="opacity:1"
        data-8500="opacity:1"
        data-9000="opacity:0"
      >
        <p
          data-6700="opacity:1;position:absolute"
          data-7200="opacity:0;position:absolute"
        >
           {t("saen.thanks")}
        </p>
        <p
          data-7000="opacity:0;position:absolute"
          data-7500="opacity:1;position:absolute"
          data-8000="opacity:0"
        >
           {t("saen.planted")}
        </p>
        <p
          data-7700="opacity:0;position:absolute"
          data-8100="opacity:1;position:absolute"
          data-9100="opacity:0"
        >
           {t("saen.ecosystem")}
        </p>
      </div>

      {/* <p className="saen__si" 
      data-0="opacity:0" 
      data-1700="opacity:0" 
      data-1750="opacity:1" 
      data-2100="opacity:0"
    >
			Step in. Stroll around. Scroll around.
			<br/>Take your time. 
		</p> */}

      <div
        className="saen__step"
        data-0="display:none"
        data-11000="display:block"
        data-17100="display:block"
      >
        {
          Array.from(t('saen.step_in')).map((letter, index) => {
            const first_data = 11500 + (1000/t('saen.step_in').length)*index;
            const spanData = {
              [`data-${first_data}`]:"position:absolute;opacity:0",
              [`data-${first_data+500}`]:"position:absolute;opacity:1",
              [`data-${first_data+4500}`]:"position:absolute;opacity:0",
            }
            return(
            <span
              key={`${letter}--${index}`}
              className="saen__step__s"
              {...spanData}
              style={{
                top: `${Math.random()*60}vh`,
                left: `${(100/t('saen.step_in').length) * index + 5}vh`
              }}
            >
              {letter}
            </span>
          )
          })
        }
      </div>
      <div
        className="saen__stroll"
        data-15000="display:none"
        data-15500="display:block"
        data-18500="display:none"
      >
         {t("saen.stroll")}
      </div>
      <div
        className="saen__scroll"
        data-15000="display:none"
        data-15500="display:block"
        data-18500="display:none"
      >
         {t("saen.scroll")}
      </div>
      <div
        className="saen__waiting"
        data-18500="display:none;opacity:1"
        data-18501="display:block;opacity:1"
        data-21700="display:block;opacity:1"
        data-21900="display:block;opacity:0"
        data-21901="display:none;opacity:0"
      >
        <p
          data-18500="opacity:0;padding-left:300px"
          data-19500="opacity:1;padding-left:0px"
        >
           {t("saen.time")}
        </p>
        <p
          data-19500="opacity:0;padding-right:200px"
          data-20500="opacity:1;padding-right:0px"
        >
           {t("saen.waiting")}{" "}
        </p>
        <p
          data-20500="opacity:0;padding-left:200px"
          data-21500="opacity:1;padding-left:0px"
        >
           {t("saen.compost")}
        </p>
      </div>

      <div
        className="saen__compost"
        data-0="opacity:0;display:none"
        data-21000="opacity:0;display:block"
        data-21200="opacity:1;display:block"
        data-22000="opacity:1;display:block"
        data-22200="opacity:0;display:block"
        data-22201="opacity:0;display:none"
      >
        <Image id="2f4ab0e2-f424-4744-b044-2ab4847c44ca" />
      </div>
    </section>
  );
};

// == Export
export default Saen;
