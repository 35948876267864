import React, { useEffect, useState } from "react";

// == Import
import "./styles.scss";
import $ from "jquery";
import { getAssetUrl } from "../../api";

// == Composant
const Audios = () => {
  useEffect(() => {
    $(".floweraudio").on("play", (event) => {
      if ($(event.target).prop("volume") === 0) {
        $(event.target).animate({ volume: 1 }, 1500);
      }
    });
    $(".hypercam-audio").on("play", (event) => {
      if ($(event.target).prop("volume") === 0) {
        $(event.target).animate({ volume: 1 }, 1500);
      }
    });
    $(".welken-audio").on("play", (event) => {
      if ($(event.target).prop("volume") === 0) {
        $(event.target).animate({ volume: 1 }, 1500);
      }
    });
    $(".kreuzen-audio").on("play", (event) => {
      if ($(event.target).prop("volume") === 0) {
        $(event.target).animate({ volume: 1 }, 1500);
      }
    });

    
    $(".floweraudio").on("pause", (event) => {
      // console.log('PAUSE');
      if(event.target.currentTime === event.target.duration) { 
        event.target.setAttribute("volume", 0); 
      }
      else if ($(event.target).prop("volume") === 1) {
        $(event.target).trigger("play");
        setTimeout(() => {
          $(event.target).trigger("pause");
        }, 1500);
        $(event.target).animate({ volume: 0 }, 1500);
      }
    });
    $(".kreuzen-audio").on("pause", (event) => {
      // console.log('PAUSE');
      if(event.target.currentTime === event.target.duration) { 
        event.target.setAttribute("volume", 0); 
      }
      else if ($(event.target).prop("volume") === 1) {
        $(event.target).trigger("play");
        setTimeout(() => {
          $(event.target).trigger("pause");
        }, 1500);
        $(event.target).animate({ volume: 0 }, 1500);
      }
    });
    $(".welken-audio").on("pause", (event) => {
      // console.log('PAUSE');
      if(event.target.currentTime === event.target.duration) { 
        event.target.setAttribute("volume", 0); 
      }
      else if ($(event.target).prop("volume") === 1) {
        $(event.target).trigger("play");
        setTimeout(() => {
          $(event.target).trigger("pause");
        }, 1500);
        $(event.target).animate({ volume: 0 }, 1500);
      }
    });
    $("video").on("play", (event) => {
      for (
        let index = 0;
        index < document.querySelectorAll(".floweraudio").length;
        index++
      ) {
        document.querySelectorAll(".floweraudio")[index].pause();
      }
    });
    if(document.querySelector(`#seedaudio--1`)) document.querySelector(`#seedaudio--1`).volume = 0.1;
    if(document.querySelector(`#seedaudio--2`))document.querySelector(`#seedaudio--2`).volume = 0.1;
    if(document.querySelector(`#seedaudio--3`))document.querySelector(`#seedaudio--3`).volume = 0.1;
    if(document.querySelector(`#seedaudio--4`))document.querySelector(`#seedaudio--4`).volume = 0.1;
    if(document.querySelector(`.hypercam-audio`))document.querySelector(`.hypercam-audio`).volume = 0.8;
  }, []);

  // const flowerAudioList = [
  //   "630ad2cb-9cb1-4dae-a387-7b6963235f28",
  //   "d3ccac5d-a0d4-49ec-91bf-aff931d4b336",
  //   "0f001179-0a13-4a89-934d-be22bf295e76",
  //   "ed90abee-a516-4978-a0c8-0d40d3dbe4b6",
  //   "779b3b08-c8b9-415a-a5f7-07fb1876e8e3",
  //   "0a1970c1-be64-4631-9d2a-f5e07f392245",
  // ];

  const flowerAudioList = [
    "https://a.storyblok.com/f/107910/x/08ec75b968/flower-short-1.mp3",
    "https://a.storyblok.com/f/107910/x/6ddb223988/flower-short-2.mp3",
    "https://a.storyblok.com/f/107910/x/8685b75211/flower-short-3.mp3",
    "https://a.storyblok.com/f/107910/x/a91ea254f6/flower-short-4.mp3",
    "https://a.storyblok.com/f/107910/x/e394902a22/flower-short-5.mp3",
    "https://a.storyblok.com/f/107910/x/0c8120f5dd/flower-short-6.mp3",
  ];

  // const circleAudioList = [
  //   "f58d465d-4eba-48b8-850c-2b32010f97bf",
  //   "111c429f-1eae-4d6a-9291-ca8ba4c09d6c",
  //   "aa340e14-9d83-45f4-83df-bdeece248e9c",
  //   "2ae8d4b0-638c-41c3-a8bb-b71ea4652a81",
  // ];

  const circleAudioList = [
    "https://a.storyblok.com/f/107910/x/5e0487be12/spinning-circle-1.mp3",
    "https://a.storyblok.com/f/107910/x/1c14395c1b/spinning-circle-2.mp3",
    "https://a.storyblok.com/f/107910/x/76825dd542/spinning-circle-3.mp3",
    "https://a.storyblok.com/f/107910/x/728d76edb9/spinning-circle-4.mp3",
  ];

  //  const seedsAudioList = [
  //   "f36c0e90-c681-462f-ba9e-afef0702bfab",
  //   "e974355d-8cee-429f-bb5b-e927789b0bc6",
  //   "d81282c9-f9ae-433a-bdc8-7fb9f2638f5e",
  //   "f8ee9b1e-6983-4e21-8b9a-e69be30345b2",
  // ];

  const seedsAudioList = [
    "https://a.storyblok.com/f/107910/x/a103f73e03/seed-1-new.mp3",
    "https://a.storyblok.com/f/107910/x/b543f8bacd/02-seeds-away.mp3",
    "https://a.storyblok.com/f/107910/x/6569a4b9d7/seed-3-new.mp3",
    "https://a.storyblok.com/f/107910/x/9dc337c8c9/seed-4-new.mp3",
  ];

  return (
    <div className="audio-container">
      {flowerAudioList.map((sound, index) => (
        <audio
          className={`floweraudio floweraudio--${index + 1}`}
          id={`floweraudio--${index + 1}`}
          key={`floweraudio--${index + 1}`}
          volume="0"
        >
          {/* <source src={getAssetUrl(sound)} /> */}
          <source src={sound} />
        </audio>
      ))}
      {circleAudioList.map((sound, index) => (
        <audio
          className={`circleaudio circleaudio--${index + 1}`}
          id={`circleaudio--${index + 1}`}
          key={`circleaudio--${index + 1}`}
        >
          {/* <source src={getAssetUrl(sound)} /> */}
          <source src={sound} />
        </audio>
      ))}
      {seedsAudioList.map((sound, index) => (
        <audio
          className={`seedaudio seedaudio--${index + 1}`}
          id={`seedaudio--${index + 1}`}
          key={`seedaudio--${index + 1}`}
          volume="0.2"
        >
          {/* <source src={getAssetUrl(sound)} /> */}
          <source src={sound} />
        </audio>
      ))}
      <audio className="general-audio" loop>
        <source src="https://a.storyblok.com/f/107910/x/3c72b65035/garden-data-background-music.mp3" />
        {/* <source src={getAssetUrl('9e8636a0-24e5-428b-960b-90a9038cd626')} /> */}
      </audio>
      <audio className="kreuzen-audio">
        <source src="https://a.storyblok.com/f/107910/x/781f660184/unverhof.mp3" />
        {/* <source src={getAssetUrl('9e8636a0-24e5-428b-960b-90a9038cd626')} /> */}
      </audio>
      <audio className="welken-audio">
        <source src="https://a.storyblok.com/f/107910/x/f215f9d136/audio_welken.mp3" />
        {/* <source src={getAssetUrl('9e8636a0-24e5-428b-960b-90a9038cd626')} /> */}
      </audio>
      <audio className="hypercam-audio" volume="1" loop>
        <source src="https://a.storyblok.com/f/107910/x/db000838a8/hypercam-1.mp3" />
        {/* <source src={getAssetUrl('9e8636a0-24e5-428b-960b-90a9038cd626')} /> */}
      </audio>
    </div>
  );
};

// == Export
export default Audios;
